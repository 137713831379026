<template>
  <a
    href="https://wa.me/573026544545?text=Me%20gustaría%20contactar%20a%20la%20tuna"
    class="whatsapp"
    target="_blank"
  >
    <i class="fa fa-whatsapp whatsapp-icon"></i
  ></a>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "WhatsappButton",
  props: {},
});
</script>

<style>
.whatsapp {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 13px;
}
</style>