<template>
  <main>
    <HomeCarrousellComponent />
  </main>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import HomeCarrousellComponent from "@/components/HomeCarrousellComponent.vue";

export default defineComponent({
  name: "HomeView",
  components: {
    HomeCarrousellComponent,
  },
  metaInfo: {
    title: "Home",
  },
});
</script>
