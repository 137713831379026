<template>
  <div class="container">
    <footer
      class="
        d-flex
        flex-wrap
        justify-content-between
        align-items-center
        py-3
        my-4
        border-top
      "
    >
      <div class="col-md-4 d-flex align-items-center">
        <a
          href="/"
          class="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1"
        >
          <img
            src="../assets/logo.svg"
            alt="Logo Tuna"
            width="30"
            height="24"
          />
        </a>
        <span class="text-muted">© 2024 Tuna de la Ean</span>
      </div>

      <ul class="nav col-md-4 justify-content-end list-unstyled d-flex">
        <li class="ms-3">
          <a
            class="text-muted"
            href="https://www.youtube.com/channel/UC945lehZOCvSNownsAjmhAQ"
            target="_blank"
          >
            <img
              src="../assets/YouTube.svg"
              width="24"
              height="24"
              alt="YouTube logo"
            />
          </a>
        </li>
        <li class="ms-3">
          <a
            class="text-muted"
            href="https://www.instagram.com/tunadelaean/"
            target="_blank"
          >
            <img
              src="../assets/instagram.svg"
              width="24"
              height="24"
              alt="Instagram logo"
            />
          </a>
        </li>
        <li class="ms-3">
          <a
            class="text-muted"
            href="https://www.facebook.com/tunadelaean"
            target="_blank"
          >
            <img
              src="../assets/facebook.svg"
              width="24"
              height="24"
              alt="Facebook logo"
            />
          </a>
        </li>
        <li class="ms-3">
          <a
            class="text-muted"
            href="https://twitter.com/tunadelaean"
            target="_blank"
          >
            <img
              src="../assets/twitter.svg"
              width="24"
              height="24"
              alt="Twitter logo"
            />
          </a>
        </li>
      </ul>
    </footer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "CustomFooter",
  props: {
    msg: String,
  },
});
</script>