<template>
  <nav class="navbar navbar-expand-lg sticky-top navbar-dark bg-dark">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">
        <img
          alt="Vue logo"
          class="navbar-logo"
          src="../assets/logo_white.svg"
        />
        Tuna de la EAN
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="collapse navbar-collapse justify-content-end"
        id="navbarSupportedContent"
      >
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link to="/services">Servicios</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/listenus">Escuchanos</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/about">Acerca De</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/contact">Contacto</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <router-view />
</template>

<style scoped>
.navbar-logo {
  max-height: 50px;
}

nav a {
  font-weight: bold;
  color: #afbac5;
  padding: 5px;
  text-decoration: none;
}

nav a.router-link-exact-active {
  color: #e6964c;
}
</style>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "NavBar",
  props: {
    msg: String,
  },
});
</script>