import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import { createApp } from 'vue'
import VueMeta from 'vue-meta'
import App from './App.vue'
import router from './router'

// Código de Google Analytics
(function () {
  const gtag = document.createElement('script');
  gtag.async = true;
  gtag.src = 'https://www.googletagmanager.com/gtag/js?id=G-LZ5H563F94';
  document.head.appendChild(gtag);

  const script = document.createElement('script');
  script.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-LZ5H563F94');
  `;
  document.head.appendChild(script);
})();

createApp(App).use(router).mount('#app')

