<template>
  <div
    id="carouselExampleCaptions"
    class="carousel slide carousel-fade mx-auto"
    data-bs-ride="carousel"
  >
    <div class="carousel-inner">
      <CarousellComponent
        v-for="photo in photos"
        :key="photo"
        :content="photo.jumbotron.content"
        :learnMoreLink="photo.jumbotron.learnMoreLink"
        :title="photo.jumbotron.title"
        :url="photo.url"
        :isActive="photo.isActive"
      />
    </div>
    <button
      class="carousel-control-prev"
      type="button"
      data-bs-target="#carouselExampleCaptions"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      data-bs-target="#carouselExampleCaptions"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>

<script lang="ts">
import CarousellComponent from "@/components/CarouselSlideComponent.vue";
import data from "../assets/data/homeData.json";

export default {
  components: {
    CarousellComponent,
  },
  computed: {
    photos() {
      return data.photos.map((item) => {
        return item;
      });
    },
  },
};
</script>

<style scoped>
.carousel {
  max-width: 1920px;
}
</style>