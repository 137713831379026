<template>
  <div :class="isActive ? 'carousel-item active' : 'carousel-item'">
    <img :src="`${publicPath}${url}`" class="d-block w-100" :alt="url" />
    <div class="carousel-caption">
      <div class="bg-dark rounded-3" style="--bs-bg-opacity: 0.7">
        <div class="container-fluid py-3">
          <h3 class="h3 fw-bold d-none d-md-block">{{ title }}</h3>
          <p>
            {{ content }}
          </p>
          <router-link
            class="btn btn-success btn-sm"
            type="button"
            :to="learnMoreLink"
          >
            Saber Más
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    title: String,
    content: String,
    learnMoreLink: String,
    url: String,
    isActive: Boolean,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>

<style scoped>
</style>